<script setup lang="ts">
const { t } = useI18n()

const total = ref(0)
const isLoading = ref(false)

useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <div class="w-full space-y-8 md:space-y-12">
    <div>
      <SwiperBanner
        :slides="[
          { image: '/webcontent/women.png', imageClass: 'brightness-80', text: t('general.homepage_carousel.first_slide.heading'), to: '/collections/9057/bundy', buttonText: t('general.homepage_carousel.first_slide.button') },
          { image: '/webcontent/man.png', imageClass: 'brightness-80', text: t('general.homepage_carousel.second_slide.heading'), to: '/collections/9069/bundy-muzi', buttonText: t('general.homepage_carousel.second_slide.button') },
        ]"
      />
    </div>

    <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
      <div class="flex flex-col items-center gap-x-2 md:max-h-110 md:flex-row">
        <DoubleBanner class="h-full" img-link="/webcontent/inflation.jpg">
          <template #content>
            <div class="h-full flex flex-col justify-evenly">
              <h2 class="block text-xl text-white font-bold uppercase">
                {{ t('general.homepage_content.banner.streetwear') }}<br>
                {{ t('general.homepage_content.banner.redefined') }}
              </h2>
              <p class="mt-1 block text-sm text-gray-300">
                {{ t('general.homepage_content.banner.content') }}
              </p>
              <div class="block">
                <NLink
                  to="/stores/6106/inflation"
                  class="mt-6 flex flex-shrink-0 items-center justify-center border border-white border-opacity-25 rounded-md bg-white bg-opacity-0 px-4 py-3 text-base text-white font-medium no-underline lg:ml-0 sm:ml-8 sm:mt-0 lg:w-full hover:bg-opacity-10 hover:text-white"
                >
                  {{ t('general.homepage_content.banner.button') }}
                </NLink>
              </div>
            </div>
          </template>
        </DoubleBanner>
        <div class="mb:mt0 mt6 w-full xs:m-1 lg:w-1/3">
          <div class="relative h-full max-h-110">
            <img
              src="/webcontent/disko-sport.png"
              alt="Disko Sport"
              class="h-full max-h-110 w-full rounded-lg object-cover object-center object-top"
            >
            <div
              class="absolute inset-x-0 bottom-0 flex flex-col items-start justify-between rounded-bl-md rounded-br-md bg-black bg-opacity-20 p-4 lg:items-start lg:p-6"
            >
              <div class="h-auto flex flex-col justify-evenly">
                <h2 class="mb-2 text-lg text-white font-bold uppercase sm:text-xl">
                  {{ t('general.homepage_content.banner_small.heading') }}
                </h2>
                <div>
                  <NLink
                    href="/cs/stores/6110/disko-sport"
                    class="n-link flex items-center justify-center border border-black rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm text-white font-medium no-underline transition hover:bg-opacity-10 sm:text-base hover:text-white"
                  >
                    {{ t('general.homepage_content.banner_small.button') }}
                  </NLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
      <div>
        <h2 class="text-2xl text-black uppercase">
          {{ t('homepage.currently_sold') }}
        </h2>
      </div>
      <div class="mb4">
        <ProductsGrid
          @total="(v) => (total = v)"
          @loading="(loading) => (isLoading = loading)"
        />
      </div>
    </div>

    <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
      <ImageBanner
        color-class="bg-black"
        :img="{ src: '/webcontent/hp_bnr.jpg', alt: 'Be inflated.' }"
      >
        <template #content>
          <div class="p10 md:p20">
            <h1 class="text-4xl font-bold">
              {{ t('general.homepage_content.banner2.new_pieces_for_every_day') }}
            </h1>
            <p class="mt-4 text-lg">
              {{ t('general.homepage_content.banner2.content') }}
            </p>
            <NLink
              to="/collections/new-arrivals"
              class="mt-5 block w-fit rounded-md bg-white p-2 px-5 text-black no-underline"
            >
              {{ t('general.homepage_content.banner2.browse_products') }}
            </NLink>
          </div>
        </template>
      </ImageBanner>
    </div>
  </div>
</template>
